
import { Component, Vue, Watch } from "vue-property-decorator";
import { UserService, GaodeService, WechatService } from "src/services";
import { Toast } from "vant";
import { ILogin } from "src/model";
// import sensorsUtils from "src/utils/sensorsUtils";
import { tools } from "src/utils";
import globalConfig from "../config/index";

@Component
export default class Login extends Vue {
  /**
   * appId
   * @private
   * @returns string
   */
  private get appId(): string {
    return (this.$route.query.appId && (this.$route.query.appId as string)) || "";
  }

  /**
   * ticket
   * @private
   * @returns string
   */
  private get ticket(): string {
    return (this.$route.query.ticket && (this.$route.query.ticket as string)) || "";
  }

  /**
   * 登录数据模型
   * @private
   * @returns ILogin
   */
  private modelData: ILogin = {
    code: "", // 企业编号
    account: "", // 登入账号
    password: "", // 登入密码
  };

  /**
   * 当路由发生变化的时候操作。
   * @protected
   * @param {any} - to 当前路由实例。
   * @returns {void}
   */
  protected async created(to: any) {
    try {
      this.modelData.code = localStorage.getItem("appid");
      // 获取机构信息
      this.getGeolocation();
      this.getOrgInfo(this.modelData.code);
      this.onLogin();
    } catch (err) {
      console.log(err);
    }
  }

  /* 获取当前位置信息
   * @private
   * @returns void
   */
  private async getGeolocation(): Promise<void> {
    let appid = localStorage.getItem("appid");
    let employerConfig = globalConfig.employerConfig[appid];
    if (employerConfig && employerConfig.unLocation) {
      return;
    }
    this.$store.dispatch("setLocation", null);
    if (await tools.isWechat()) {
      // 如果是微信环境
      this.weixinLocation();
    } else {
      let data = await GaodeService.instance.getBaiduLocation();
      this.$store.dispatch("setLocation", data);
    }
  }

  /* 获取当前位置信息 -- 微信
   * @private
   * @returns void
   */
  private async weixinLocation(): Promise<void> {
    let currentUrl = location.href;
    let { content: result } = await WechatService.instance.getWechatJSSDK(currentUrl);
    let loca = await WechatService.instance.getWechatLocation(result); // 获取经纬度-腾讯
    let locat = await GaodeService.instance.getCity((loca as any).longitude, (loca as any).latitude); // 通过经纬度获取城市-高德
    this.$store.dispatch("setLocation", locat);
  }

  /**
   * 获取机构详情
   * @private
   * @returns void
   */
  private async getOrgInfo(code: string): Promise<void> {
    try {
      let { content: result } = await UserService.instance.getOrgInfo(code);
      if (result.data) {
        this.$store.dispatch("setOrgInfo", result.data);
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 登录
   * @private
   * @returns void
   */
  private async onLogin(): Promise<void> {
    let uucode = dsBridge.call("getUserCode");
    let uutoken = dsBridge.call("getUUCToken");
    let uuid = dsBridge.call("getUUCUuid");
    try {
      let { content: result } = await UserService.instance.vivoLogin({
        uuid: uuid,
        uucToken: uutoken,
        userCode: uucode,
        code: this.modelData.code,
      });
      if (result.data) {
        // 绑定神策用户数据
        // sensorsUtils.bind("identity_Id",result.data.userId +"");
        this.$store.dispatch("setUserInfo", result.data);
        this.$nextTick(() => {
          this.$router.replace({ name: "home" });
        });
      }
    } catch (err) {
      Toast(err);
    }
  }
}
